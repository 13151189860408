// Generated by Framer (88fb678)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PzeXvbVJQ"];

const variantClassNames = {PzeXvbVJQ: "framer-v-tog6ks"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "PzeXvbVJQ", title: BEtYPsgXO = "View", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PzeXvbVJQ", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-innNX", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-tog6ks", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PzeXvbVJQ"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.2)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backdropFilter: "blur(15px)", backgroundColor: "rgb(210, 246, 90)", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, WebkitBackdropFilter: "blur(15px)", ...style}} transition={transition}><Text __fromCanvasComponent alignment={"left"} className={"framer-1os4eeu"} data-framer-name={"View"} fonts={["GF;Clash Display-500"]} layoutDependency={layoutDependency} layoutId={"LwIO331v6"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>View</span><br></span></span>"} style={{"--framer-font-family": "\"Clash Display\", serif", "--framer-font-size": "12px", "--framer-font-style": "normal", "--framer-font-weight": 500, "--framer-letter-spacing": "1.5px", "--framer-line-height": "100%", "--framer-text-alignment": "left", "--framer-text-color": "rgb(0, 0, 0)", "--framer-text-decoration": "none", "--framer-text-transform": "uppercase"}} text={BEtYPsgXO} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-innNX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-innNX * { box-sizing: border-box; }", ".framer-innNX .framer-15t5lva { display: block; }", ".framer-innNX .framer-tog6ks { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 32px; height: 100px; justify-content: center; overflow: visible; padding: 10px 20px 10px 20px; position: relative; width: 100px; }", ".framer-innNX .framer-1os4eeu { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-innNX .framer-tog6ks { gap: 0px; } .framer-innNX .framer-tog6ks > * { margin: 0px; margin-left: calc(32px / 2); margin-right: calc(32px / 2); } .framer-innNX .framer-tog6ks > :first-child { margin-left: 0px; } .framer-innNX .framer-tog6ks > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"BEtYPsgXO":"title"}
 */
const FramerMydUVD0RH: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerMydUVD0RH;

FramerMydUVD0RH.displayName = "Cursor";

FramerMydUVD0RH.defaultProps = {height: 100, width: 100};

addPropertyControls(FramerMydUVD0RH, {BEtYPsgXO: {defaultValue: "View", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerMydUVD0RH, [])